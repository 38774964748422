<template>
  <div class="section">
    <div class="container">
      <div class="row row-split-content" style="margin:0px">
        <div class="content-width-medium">
          <h1>{{ content.headline }}</h1>
          <div class="text-large">{{ content.description }}</div>
          <div class="form-wrapper margin-top w-form">
            <form class="form" ms-signup="true" @submit.prevent="processForm">
              <div class="form-group">
                <div class="form-grid-halves">
                  <input
                    :rules="[(v) => !!v || 'Name is required']"
                    v-model="firstName"
                    type="text"
                    class="form-input w-input"
                    maxlength="256"
                    name="first-name"
                    data-name="First Name"
                    placeholder="First Name"
                    id="first-name"
                    required
                    ms-field="first-name"
                  />
                  <input
                    :rules="[(v) => !!v || 'Name is required']"
                    v-model="lastName"
                    type="text"
                    class="form-input w-input"
                    maxlength="256"
                    name="Last-Name"
                    data-name="Last Name"
                    placeholder="Last Name"
                    id="Last-Name-5"
                    ms-field="last-name"
                  />
                </div>
              </div>
              <div class="form-group">
                <input
                  :rules="[(v) => !!v || 'Email is required']"
                  v-model="email"
                  type="email"
                  class="form-input w-input"
                  maxlength="256"
                  name="email"
                  data-name="Email"
                  placeholder="Email Address"
                  id="email"
                  required
                  ms-field="email"
                />
              </div>
              <div class="form-group">
                <input
                  :rules="[(v) => !!v || 'Company is required']"
                  v-model="companyName"
                  type="text"
                  class="form-input w-input"
                  maxlength="256"
                  name="company-name"
                  data-name="Company Name"
                  placeholder="Company Name"
                  id="company-name"
                  ms-field="company"
                />
              </div>
              <div class="form-group">
                <input
                  :rules="[rules.password]"
                  v-model="password"
                  type="password"
                  class="form-input w-input"
                  maxlength="256"
                  name="password"
                  data-name="Password"
                  placeholder="Password"
                  id="password"
                  required
                  ms-field="password"
                />
              </div>
              <div class="form-group">
                <input
                  :rules="[(v) => !!v || 'Passwords must match']"
                  v-model="passwordConfirm"
                  type="password"
                  class="form-input w-input"
                  maxlength="256"
                  name="password"
                  data-name="Password"
                  placeholder="Confirm Password"
                  id="password"
                  required
                  ms-field="password"
                />
              </div>

              <input
                type="submit"
                :value="content.button"
                data-wait="Please wait..."
                class="button w-button"
                data-membership-id="5e08febaaabc680017dfd539"
              />
            </form>
            <div class="form-feedback bg-success w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="form-feedback bg-error w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
            <p class="login">Trying to login? <a href="https://nest.theagentnest.com">Click Here</a></p>
          </div>
        </div>
        <div class="content-width-medium">
          <ul class="w-list-unstyled">
            <li
              v-for="bennie in content.benefits"
              v-bind:key="bennie.benefit"
              class="icon-list-item align-center"
            >
              <div class="circle-small bg-primary-2">
                <img src="/images/icon-check-small.svg" alt />
              </div>
              <h5 class="no-bottom-space">{{ bennie.benefit }}</h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import PageService from "@/services/pageService.js";
import { teamService } from "@/services";
import auth0 from "auth0-js";
export default {
  name: "home",
  components: {},
  data() {
    return {
      overlay: false,
      content: [],
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      password: null,
      passwordConfirm: null,
      rules: {
        required: (value) => !!value || "Required.",
        password: (value) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters with at least one capital letter and a number."
          );
        },
        passwordsMatch: (value) => {
          return value === this.password || `Passwords don't match`;
        },
      },
    };
  },
  methods: {
    processForm() {
      if (this.password != this.passwordConfirm) {
        this.$swal("Error", "Passwords Must Match");
        return;
      }
      this.overlay = true;
      var team = {
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.companyName,
        email: this.email,
        password: this.password,
      };
      teamService
        .signup(team)
        .then((data) => {
          console.log(data);
          this.overlay = false;

          this.$router.push({
            name: "StripePricingTable",
            query: { email: this.email, tenant: data.id },
          });
        })
        .catch((error) => {
          if (error.response.data.toLowerCase() == "user already exists") {
            
             this.$swal({
        title: 'Welcome Back!',
        html: '<p>You already have an account with The Agent Nest. You can login and manage your account. If you forgot your password click on "Trouble Signing In?"</p>',
        showCancelButton: false,
        confirmButtonText: 'My Account',
        focusConfirm: false,
        preConfirm: () => {
          window.location.href = 'https://nest.theagentnest.com';
        },
      });

            this.overlay = false;
          } else {
            this.$swal("Error", error.response.data);
            this.overlay = false;
          }
        });
      // Initialize client
      //Old code to directly talk to Auth0
      // var webAuth = new auth0.WebAuth({
      //   domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
      //   clientID: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
      // });

      // console.log(this.$auth);
      // webAuth.signup(
      //   {
      //     connection: "Username-Password-Authentication",
      //     email: this.email,
      //     password: this.password,
      //     given_name: this.firstName,
      //     family_name: this.lastName,
      //     name: this.firstName + " " + this.lastName,
      //     nickname: this.firstName,
      //     user_metadata: { "self_sign_up": "true" },
      //   },
      //   function (err) {
      //     console.log(err);
      //     if (err)
      //     {
      //       if(err.code=="invalid_password")
      //       {
      //         that.$swal("Password Too Weak.",
      //          err.policy
      //       );
      //       }
      //       else{
      //           that.$swal(
      //          err.description
      //       );
      //       }

      //     }
      //     else{
      //       that.$swal
      //   .fire({
      //     icon: "success",
      //     title: "Success",
      //     text:
      //       "Your account has been created!"
      //   })
      //   .then(result => {
      //     if (result.value) {
      //       that.$router.push("/");
      //     }
      //   });
      //     }
      //   }
      // );
    },
  },
  created() {
    PageService.getPage("sign-up")
      .then((response) => {
        this.content = response.data.data.fields;
      })
      .catch((error) => {
        console.log("there was an error getting the content", error);
      });
  },
};
</script>
<style scoped>
.login{
  margin-top: 5px;
}
</style>